<template>
  <div class="products">
      <ItemFilter :placeholder="$t('Search by product name')" default="productName" @search="searchItems">
        <el-option :label="$t('Product Name Search')" value="productName"></el-option>
      </ItemFilter>
      <div class="categories-filter">
        <span class="filter-label"> {{ $t('Categories') }}</span>
        <el-checkbox-group v-model="targetCategories" @change="onCheckboxChange">
          <el-checkbox v-if="langcode == 'zh'" :key="categorie.id" v-for="categorie in categories" :label="categorie.categorie_name_zh"></el-checkbox>
          <el-checkbox v-if="langcode == 'en'" :key="categorie.id" v-for="categorie in categories" :label="categorie.categorie_name_en"></el-checkbox>
        </el-checkbox-group>
      </div>
      <div class="product-list">
        <div class="row">
          <div class="col-md-3" v-for="product in products">
            <ProductBox :product="product"/>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Common from '@/lib/common';
import Product from '@/lib/product';
import ItemFilter from '@/components/Filter.vue';
import ProductBox from '@/components/product/ProductBox.vue';

export default {
  name: 'ProductList',
  components: {
    ItemFilter,
    ProductBox,
  },
  mounted(){
    this.loadProducts();
    this.loadCategories();
  },
  methods: {
    searchItems(form){
      const searchField = {};
      let newItems = [];
      switch(form.filterTarget){
        case 'productName':
        if(this.langcode === 'zh'){
          searchField['product_name_zh'] = form.filterValue;
        }else{
          searchField['product_name_en'] = form.filterValue;
        }
        newItems = Common.filterItems(searchField, this.orignalProducts);
        this.searchProducts = newItems;
        this.products = newItems;
        break;
      }
    },
    onCheckboxChange(){
      const self = this; //Use self to replace this in filter function
      const targetCategories = self.targetCategories;
      const newItems = self.orignalProducts.filter(function(item){
        let itemName = '';
        const itemCateogires = item.product_categories;
        for(let i = 0; i < itemCateogires.length; i++){
          const itemCateogire = itemCateogires[i];
          if(self.langcode === 'zh'){
            if(targetCategories.includes(itemCateogire.categorie_name_zh)){
              return true;
            }
          }else{
            if(targetCategories.includes(itemCateogire.categorie_name_en)){
              return true;
            }
          }
        }
      });
      self.products = newItems;
      //If do not had any selected categoires ,reset it
      if(targetCategories.length === 0 ){
        self.products = self.orignalProducts;
      }
    },
    async loadProducts(){
      try{
        const loginInfo = Common.getLoginInfo();
        let products = await Product.loadAllProducts(this.apiUrl, loginInfo);
        products = this._loadProductVariations(products);
        this.orignalProducts = products;
        this.products = products;
      }catch(err){
        console.log(err);
      }
    },
    _loadProductVariations(products){
      for(let i = 0; i < products.length; i++){
        const productVariations = JSON.parse(products[i].product_variations);
        const variationsOptions = [];
        for(let i = 0; i < productVariations.length; i++){
          const variation = {
            variation_name_en: productVariations[i].variation_name_en,
            variation_name_zh: productVariations[i].variation_name_zh,
            option: null,
          };
          variationsOptions.push(variation);
        }
        products[i].product_variations = productVariations;
        products[i].variation_options = variationsOptions;
      }
      return products;
    },
    async loadCategories(){
      const loginInfo = Common.getLoginInfo();
      try{
        const categories = await Product.loadAllCategories(this.apiUrl, loginInfo);
        this.categories = categories;
      }catch(err){
        console.log(err);
      }
    },
    ...mapActions(['addOrderItem']),
  },
  data(){
    return {
      products: [],
      orignalProducts: [],
      categories: [],
      targetCategories: [],
    }
  },
  computed: mapState({
    langcode: state => state.langcode,
    apiUrl: state => state.apiUrl,
  }),
}
</script>

<style scoped lang="scss">
.content-header{
  display: flex;
  h2{
    flex: 1;
  }
  .action-button{
    margin-right: 20px;
  }
}
.categories-filter{
  padding-left: 20px;
  font-weight: bold;
  margin-bottom: 50px;
  display: flex;
  .filter-label{
    margin-right: 20px;
  }
}

.product-list{
  padding-left: 20px;
  .product{
    margin-bottom: 20px;
  }
  .product-image{
    height: 150px;
    width: 150px;
    img{
      max-width: 100%;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  .product-name{
    margin-top: 10px;
    font-weight: bold;
  }
  .product-price{
    margin-top: 10px;
  }
  .product-variation{
    display: flex;
    margin-top: 10px;

    .variation-label{
      margin-right: 10px;
    }
  }
  .action-button-wrapper{
    margin-top: 10px;
  }
}


</style>
