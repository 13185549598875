<template>
  <div class="create-order">
    <div class="page-content">
      <div class="order-wrapper">
        <ProductList />
        <div class="order-detail-wrapper">
          <h2>{{ $t('Order Detail')}}</h2>
          <div class="order-items">
            <div class="label">{{ $t('Order Items:')}}</div>
            <ul class="order-items-list">
              <li class="order-item" v-for="item in newOrderItems">
                <div class="product-name" v-if="langcode === 'en'">
                  {{ item.product_name_en }}
                </div>
                <div class="product-name" v-if="langcode === 'zh'">
                  {{ item.product_name_zh }}
                </div>
                <div class="offcanvas-cart-item-details-quantity">{{item.quantity}}x</div>
                <div class="offcanvas-cart-item-details-price">${{ getPrice(item.product_regular_price) }}</div>
                <div class="variation-option">{{ getVariations(item.variation_options) }}</div>
              </li>
            </ul>
          </div>
          <div class="order-amount">
            <div class="offcanvas-cart-total-price-text">{{ $t('Total:') }}</div>
            <div class="offcanvas-cart-total-price-value">${{ totalAmount }}</div>
          </div>
          <button class="btn btn-primary btn-order" @click="createOrder">{{ $t('Create Order')}}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import ProductList from '@/components/product/ProductList';
import Common from '@/lib/common';
import Order from '@/lib/order';
import Product from '@/lib/product';

export default {
  name: 'CreateOrder',
  components: {
    ProductList
  },
  methods:{
    async createOrder(){
      if(this.newOrderItems.length === 0){
        this.$message({
          message: this.$t('You should add at lease one product to order.'),
          type: 'error'
        });
        return;
      }
      const loginInfo = Common.getLoginInfo();
      const form = {
        customerId: null,
        products: [],
        paymentMethod: 'POS',
      };
      for(let i = 0; i < this.newOrderItems.length; i++){
        const item = this.newOrderItems[i];
        const product = {
          id: item.id,
          quantity: item.quantity,
          variations: item.variation_options,
        };
        form.products.push(product);
      }
      try{
        const order = await Order.createOrder(this.apiUrl, form, loginInfo);
        const orderId = order['order_id'];
        this.cleanOrderItem();
        this.$router.push({ name: 'CompleteOrder' , params: {id: orderId} } );
      }catch(err){
        console.log(err);
      }
    },
    getVariations(variationOptions){
      let optionStrings = [];
      const langcode = this.$i18n.locale;
      for(let i = 0; i < variationOptions.length; i++){
        const variation = variationOptions[i];
        const variationOption = {};
        if(langcode === 'en'){
          const optionString = `${variation.variation_name_en}: ${variation.option.option_name_en}`;
          optionStrings.push(optionString);
        }else{
          const optionString = `${variation.variation_name_zh}: ${variation.option.option_name_zh}`;
          optionStrings.push(optionString);
        }
      }
      optionStrings = optionStrings.join(',');
      return optionStrings;
    },
    getPrice(price){
      const priceNumber = Number(price);
      return priceNumber;
    },
    ...mapActions(['cleanOrderItem']),
  },
  computed: {
    ...mapState({
      newOrderItems: state => state.order.newOrderItems,
      langcode: state => state.langcode,
      apiUrl: state => state.apiUrl,
    }),
    ...mapGetters({
      totalAmount: 'totalAmount',
    })
  }
}
</script>

<style scoped lang="scss">
.create-order{
  .content-header{
    position: absolute;
    top:0;
  }
  .page-content{
    padding-top: 5px;
    padding-right: 0;
    padding-bottom: 0;
    background-color: white;
    margin-right: 0;
    margin-bottom: 0;
  }
  .order-wrapper{
    display: flex;
    .products{
      flex-basis: 70%;
      margin-right: 30px;
      padding-top: 30px;
    }
  }
  .order-detail-wrapper{
    background-color: #F6F6F6;
    flex-basis: 29%;
    padding-left: 30px;
    padding-top: 20px;
    h2{
      margin: 8px 0 0;
      font-weight: 400;
      font-size: 17px;
      display: block;
      color: #008edd;
      border-bottom: none;
    }
  }
  .order-items{
    .label{
      margin-top: 30px;
      margin-bottom: 10px;
      font-weight: bold;
    }
    .order-items-list{
      padding: 0;
      list-style: none;
    }
    .order-item{
      display: flex;
      margin-bottom: 10px;
      .product-name{
        margin-right: 10px;
      }
      .offcanvas-cart-item-details-quantity{
        margin-right: 10px;
      }
      .offcanvas-cart-item-details-price{
        margin-right: 10px;
      }
    }
  }
  .order-amount{
    display: flex;
    .offcanvas-cart-total-price-text{
      margin-right: 10px;
      font-weight: bold;
    }
  }
  .btn-order{
    margin-top: 20px;
  }
}

</style>
