<template>
  <div class="product" v-if="product">
    <div class="product-image">
      <img class="img-fluid" v-if="getProductImage(product.product_images)" :src="getProductImage(product.product_images)"/>
      <img class="img-fluid" v-else src="@/assets/no-image.jpg" />
    </div>
    <div class="product-detail">
      <div class="product-name" v-if="langcode === 'en'">
        {{ product.product_name_en }}
      </div>
      <div class="product-name" v-if="langcode === 'zh'">
        {{ product.product_name_zh }}
      </div>
      <div class="product-variation" v-if="product.product_variations" v-for="variation in product.product_variations">
        <select name="product" @change="updateVariation($event, variation)">
          <option disabled selected hidden v-if="langcode === 'en'" :value="variation.variation_name_en">{{variation.variation_name_en}}</option>
          <option disabled selected hidden v-if="langcode === 'zh'" :value="variation.variation_name_zh">{{variation.variation_name_zh}}</option>
          <option v-for="option in variation.options" :value="option.option_name_en" v-if="langcode === 'en'">
            {{ option.option_name_en }}
          </option>
          <option v-for="option in variation.options" :value="option.option_name_en" v-if="langcode === 'zh'">
            {{ option.option_name_zh }}
          </option>
        </select>
      </div>
      <div class="product-price">
        HK$ {{ product.product_regular_price  }}
      </div>
      <div class="action-button-wrapper">
        <button class="btn btn-primary" @click="addItem(product)">{{ $t('Add To Order')}}</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'ProductBox',
  props: ['product'],
  mounted(){
    this.selectedVariations = this.product.variation_options;
  },
  methods: {
    addItem(product){
      if(product.had_variation){
        if(!this._checkOptionSelected(this.selectedVariations)){
          this.$message({
            message: this.$t('You should select all option of variation before add to the order.'),
            type: 'error'
          });
          return;
        }
        product.variation_options = this.selectedVariations;
      }
      this.addOrderItem(product);
    },
    _checkOptionSelected(variations){
      for(let i = 0; i < variations.length; i++){
        const variation = variations[i];
        if(!variation.option){
          return false;
        }
      }
      return true;
    },
    getProductCategoires(product){
      const productCategories = [];
      for(let i = 0; i < product.product_categories.length; i++){
        const productCategorie = product.product_categories[i];
        if(productCategorie){
          if(this.langcode === 'zh'){
            productCategories.push(productCategorie.categorie_name_zh);
          }else{
            productCategories.push(productCategorie.categorie_name_en);
          }
        }
      }
      const productCategoriesString = productCategories.join(', ');
      if(!productCategoriesString){
        return '';
      }
      return productCategoriesString;
    },
    updateVariation(e, variation) {
      const optionName = e.target.value;
      const targetOption = variation.options.find(function(item){
        return item.option_name_en === optionName || item.option_name_zh === optionName;
      });
      const targetVariationIndex = this.selectedVariations.findIndex(function(item){
        return item.variation_name_en === variation.variation_name_en && item.variation_name_zh === variation.variation_name_zh;
      });

      if(targetVariationIndex !== -1){
        this.selectedVariations[targetVariationIndex].option = targetOption;
      }
    },

    getProductImage(imageField){
      if(imageField){
        const images = JSON.parse(imageField);
        const image = images[0];
        return image;
      }
    },
    ...mapActions(['addOrderItem']),
  },
  data(){
    return {
      selectedVariations: [],
    }
  },
  computed: mapState({
    langcode: state => state.langcode,
    apiUrl: state => state.apiUrl,
  }),
}
</script>

<style scoped lang="scss">
.product{
  margin-bottom: 20px;
}
.product-image{
  height: 150px;
  width: 150px;
  img{
    max-width: 100%;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}
.product-name{
  margin-top: 10px;
  font-weight: bold;
}
.product-price{
  margin-top: 10px;
}
.product-variation{
  display: flex;
  margin-top: 10px;

  .variation-label{
    margin-right: 10px;
  }
}
.action-button-wrapper{
  margin-top: 10px;
}
</style>
